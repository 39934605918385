import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/ComponentStyles/Slider.scss';

export const Slider = (props: any) => {
    return (
        <div className="carousel-cnt">
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={`${process.env.PUBLIC_URL}/img/home/slider/kamerplant.jpg`}
                        alt="Eerste slide"
                    />
                    <Carousel.Caption>
                        <h3>Welkom</h3>
                        {/* <p></p> */}
                        {/* <Link className="main-btn" to="/bestel">Bekijk assortiment</Link> */}
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}
